import {DtoOktaConfigurationVersionsRequest} from "@/classes/dtos/okta/configuration/dto.okta.configuration.versions.request";
import {get} from "lodash";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    list: [],
    disableControls: false,
    watchingInterval: null,
    countingInterval: null,
    watchingMetadata: null,
  },
  getters: {
    dataReady: (state) => Boolean(state.list.length),
    watchingInProgress: (state) => Boolean(state.watchingInterval && state.countingInterval),
    deployed: (state) => get(state, 'list', []).find(version => version.active),
    latest: (state) => {
      const versions = get(state, 'list', []).map(release => Number(release.version));
      return Math.max(...versions);
    }
  },
  mutations: {
    wipe: (state) => state.list = [],
  },
  actions: {
    async updateVersions({state}) {
      const request = new DtoOktaConfigurationVersionsRequest();
      const response = await request.$send();
      state.list = response.items;
    },
    watchServerVersionDeployStatus({state}, {version, toast, title}) {
      const timePassed = () => {
        const start = get(state, 'watchingMetadata.start', new Date());
        const now = new Date();
        const difference = now.getTime() - start.getTime();
        const total = difference / 1000;
        let minutes = Math.floor(total / 60);
        let seconds = (total - minutes * 60).toFixed(0);
        if (minutes == 0) return `${seconds}s`;
        if (seconds == '60') (minutes += 1) && (seconds = '0');
        return `${minutes}m ${seconds}s`;
      }
      const updateToast = () => toast.message = `DEPLOY IN PROGRESS: ${title} - ${timePassed()}`;

      state.watchingMetadata = { count: 0, seconds: 5, start: new Date() };
      state.countingInterval = updateToast() && setInterval(updateToast, 1000);
      state.watchingInterval = setInterval(async () => {
        const request = new DtoOktaConfigurationVersionsRequest();
        const response = await request.$send();
        const bundle = response?.items || [];
        const active = bundle.find(version => version.active)?.version;
        if (active != version) return null;
        state.watchingInterval = clearInterval(state.watchingInterval);
        state.countingInterval = clearInterval(state.countingInterval);
        toast.dismiss();
        Vue.$toast.open({ message: `DEPLOY SUCCESSFUL: ${title} - ${timePassed()}`, duration: 0 });
        state.list = bundle;
        state.watchingMetadata.count += 1;
      }, state.watchingMetadata.seconds * 1000)
    }
  }
}
