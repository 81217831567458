






import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class LayoutDefault extends Vue {
  @Prop() something: number;
}
