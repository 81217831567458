import {ModelAbstraction} from "@/classes/abstractions/model.abstraction";
import {IsBoolean, IsDateString, IsNumber, IsNumberString, IsOptional, IsPositive, IsString} from "class-validator";

export class ConfigurationVersionModel extends ModelAbstraction {

  @IsString()
  @IsNumberString()
  @IsPositive()
  version: string;

  @IsBoolean()
  @IsOptional()
  active?: boolean;

  @IsString()
  @IsDateString()
  @IsOptional()
  deployStartDate?: string;

  @IsString()
  @IsDateString()
  @IsOptional()
  deployEndDate?: string;

  @IsString()
  @IsOptional()
  deployStatus?: string;

  @IsNumber()
  @IsPositive()
  @IsOptional()
  deploymentNumber?: number;
}
