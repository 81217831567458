import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import {LoginCallback} from '@okta/okta-vue'
import name from './names';

const {VUE_APP_OKTA_REDIRECT} = process.env;

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    meta: { requiresAuth: true },
    component: () => import('../views/Index.vue'),
    children: [
      {
        path: '/applications',
        name: name.Applications,
        component: () => import('../views/Index/Applications.vue')
      },
      {
        path: '/versions',
        name: name.Versions,
        component: () => import('../views/Index/Versions.vue')
      },
      {
        path: '/audiences',
        name: name.Audiences,
        component: () => import('../views/Index/Audiences.vue')
      },
      {
        path: '/features',
        name: name.Features,
        component: () => import('../views/Index/Features.vue')
      },
      {
        path: '/rule-evaluators',
        name: name.RuleEvaluators,
        component: () => import('../views/Index/RuleEvaluators.vue')
      },
    ]
  },
  {path: VUE_APP_OKTA_REDIRECT, component: LoginCallback}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
