import {DtoOktaConfigurationAbstraction} from "./dto.okta.configuration.abstraction";
import {ConfigurationVersionModel} from "@/classes/models/ConfigurationVersion.model";
import {IsArray, IsInstance} from "class-validator";
import {Type} from "class-transformer";

export class DtoOktaConfigurationVersionsRequest extends DtoOktaConfigurationAbstraction {

  @Type(() => ConfigurationVersionModel)
  @IsInstance(ConfigurationVersionModel, {each: true})
  @IsArray()
  items: ConfigurationVersionModel[];

  async $send() {
    return await this.$http('get', 'configurations');
  }
}
