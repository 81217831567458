import {DtoOktaConfigurationAbstraction} from "./dto.okta.configuration.abstraction";
import {IsString} from "class-validator";

export class DtoOktaConfigurationExtractSpecificRequest extends DtoOktaConfigurationAbstraction {

  audiences;

  features;

  @IsString()
  version: string;

  async $send(version) {
    return await this.$http('get', `configurations/${version}`);
  }
}
