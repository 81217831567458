import {get} from "lodash";
import {DtoOktaApplicationsRequest} from "@/classes/dtos/okta/configuration/dto.okta.applications.request";

export default {
  namespaced: true,
  state: {
    list: [],
    active: null,
  },
  getters: {
    dataReady: (state) => Boolean(state.list.length),
    opened: (state) => get(state, 'active', null),
  },
  mutations: {
    wipe: (state) => state.list = [],
  },
  actions: {
    async fetch({state, dispatch }) {
      const request = new DtoOktaApplicationsRequest();
      const response = await request.$send();
      state.list = response;
      state.active = response?.length ? response[0] : null;

      await dispatch('configuration/fetchLatest', {}, { root: true });
      await dispatch('versions/updateVersions', {}, { root: true });
    },
  }
}
