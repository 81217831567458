import 'reflect-metadata';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'reset.css';
import 'normalize.css';
import './main.scss';
import '../node_modules/vue-multiselect/dist/vue-multiselect.min.css';

import './plugins/BUPlugin';
import './plugins/OktaPlugin';
import './plugins/ToastPlugin';
import './plugins/UtilsPlugin';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
