import {DtoOktaConfigurationAbstraction} from "./dto.okta.configuration.abstraction";
import {IsString} from "class-validator";

export class DtoOktaConfigurationExtractLatestRequest extends DtoOktaConfigurationAbstraction {

  audiences;

  features;

  @IsString()
  version: string;

  async $send() {
    return await this.$http('get', 'configurations/latest');
  }
}
