import {set} from "lodash";

export default {
  namespaced: true,
  state: {
    user: null,
    token: null,
  },
  getters: {
    dataReady: ({user, token}) => [user, token].every(value => !!value)
  },
  mutations: {
    dataReset: (state) => ['user', 'token'].forEach(key => set(state, key, null)),
    dataSet: (state, payload) => ['user', 'token'].forEach(key => set(state, key, payload[key])),
  }
}