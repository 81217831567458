




import { Vue, Component } from 'vue-property-decorator';
import LayoutDefault from './layouts/LayoutDefault.vue';

@Component({
  components: { LayoutDefault }
})
export default class App extends Vue {}
