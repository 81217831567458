import {IsArray, IsInstance} from "class-validator";
import {Type} from "class-transformer";
import {ApplicationModel} from "@/classes/models/Application.model";
import {DtoOktaApplicationsAbstraction} from "@/classes/dtos/okta/configuration/dto.okta.applications.abstraction";

export class DtoOktaApplicationsRequest extends DtoOktaApplicationsAbstraction {

  @Type(() => ApplicationModel)
  @IsInstance(ApplicationModel, {each: true})
  @IsArray()
  items: ApplicationModel[];

  async $send() {
    return await this.$http('get', 'applications');
  }
}
