import Vue from 'vue'
import Vuex from 'vuex'
import session from './modules/session.module';
import configuration from './modules/configuration.module';
import versions from './modules/versions.module';
import applications from './modules/applications.module';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { session, configuration, versions, applications }
})
