import {Exclude} from 'class-transformer';
import {ModelAbstraction} from './model.abstraction';

export class DtoAbstraction extends ModelAbstraction {

  @Exclude()
  $axios;

  @Exclude()
  $httpOptions;

  @Exclude()
  $loading = false;

  @Exclude()
  $status;

  @Exclude()
  $error;

  async $http(method, url, {payload, params} = {}) {
    this.$loading = true;
    let response;

    try {
      response = await this.$axios({method, url, params, data: payload, ...this.$httpOptions});
      this.$error = undefined;
    } catch (error) {
      response = error.response;
      this.$error = error;
    }

    this.$loading = false;
    this.$status = response.status;
    if (!response.data || this.$error) return this;

    let instance = new this.constructor().fromJson(response.data);
    instance.$status = response.status;

    return instance;
  }

  constructor(axios, $httpOptions = {}) {
    super();
    this.$axios = axios;
    this.$httpOptions = $httpOptions;
  }
}
