import {set, isEqual, cloneDeep} from "lodash";
import {DtoOktaConfigurationExtractLatestRequest} from "@/classes/dtos/okta/configuration/dto.okta.configuration.extract-latest.request";
import {DtoOktaConfigurationExtractSpecificRequest} from "@/classes/dtos/okta/configuration/dto.okta.configuration.extract-specific.request";

export default {
  namespaced: true,
  state: {
    edited: null,
    stored: null,
    details: null,
    audience: null,
  },
  getters: {
    dataReady: ({edited, stored}) => [edited, stored].every(value => !!value),
    isChanged: ({edited, stored}) => !isEqual(edited, stored),
    version: ({stored}) => stored?.version,
  },
  mutations: {
    wipe: (state) => {
      ['edited', 'stored', 'details', 'audience'].forEach(key => set(state, key, null));
    },
    setConfig: (state, config) => {
      ['edited', 'stored'].forEach(key => set(state, key, cloneDeep(config)));
      ['details', 'audience'].forEach(key => set(state, key, null));
    },
    removeFeature: (state, record) => {
      state.edited.features = state.edited.features.filter(feature => !isEqual(feature, record));
      if (isEqual(state.details, record)) state.details = null;
    },
    removeAudience: (state, record) => {
      state.edited.audiences = state.edited.audiences.filter(audience => !isEqual(audience, record));
      if (isEqual(state.audience, record)) state.audience = null;
    },
    setDetails: (state, record) => state.details = record,
    setAudience: (state, record) => state.audience = record,
  },
  actions: {
    async fetchLatest({ commit }) {
      commit('wipe');
      const request = new DtoOktaConfigurationExtractLatestRequest();
      const response = await request.$send();
      const {audiences, features, version} = response;
      commit('setConfig', {audiences, features, version});
    },
    async fetchSpecific({ commit }, version) {
      const request = new DtoOktaConfigurationExtractSpecificRequest();
      const response = await request.$send(version);
      var {audiences, features, version} = response;
      commit('setConfig', {audiences, features, version});
    }
  }
}
