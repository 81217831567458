import {get} from 'lodash';
import {DtoAbstraction} from '@/classes/abstractions/dto.abstraction';
import axios from 'axios';
import store from '@/store/index';

export class DtoOktaApplicationsAbstraction extends DtoAbstraction {
  constructor() {
    const baseURL = process.env.VUE_APP_FEATURES_API_URL;
    const token = get(store, 'state.session.token');
    const headers = { Authorization: ['Bearer', token].join(' ')};

    super(axios, {baseURL, headers})
  }
}
