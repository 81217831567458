import Vue from 'vue';
import {OktaAuth} from '@okta/okta-auth-js';
import OktaVue from '@okta/okta-vue';

const {
  VUE_APP_OKTA_ISSUER,
  VUE_APP_OKTA_CLIENT_ID,
  VUE_APP_OKTA_REDIRECT
} = process.env;

const oktaAuth = new OktaAuth({
  issuer: VUE_APP_OKTA_ISSUER || '',
  clientId: VUE_APP_OKTA_CLIENT_ID || '',
  redirectUri: window.location.origin + VUE_APP_OKTA_REDIRECT,
  scopes: ['openid', 'profile', 'email', 'features', 'fm-groups']
});

Vue.use(OktaVue, {oktaAuth});
