import {get} from 'lodash';
import {DtoAbstraction} from '@/classes/abstractions/dto.abstraction';
import axios from 'axios';
import store from '@/store/index';

export class DtoOktaConfigurationAbstraction extends DtoAbstraction {
  constructor() {
    const token = get(store, 'state.session.token');
    const headers = { Authorization: ['Bearer', token].join(' ')};
    const activeApp = get(store, 'state.applications.active');
    const baseURL = process.env.VUE_APP_FEATURES_API_URL + `/applications/${ activeApp.applicationId }`;
    super(axios, {baseURL, headers})
  }
}
