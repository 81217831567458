import {validateSync} from 'class-validator';
import {plainToClass, deserialize} from 'class-transformer';

export class ModelAbstraction {
  fromJson(json) {
    return plainToClass(this.constructor, json);
  }

  fromString(string) {
    return deserialize(this.constructor, string);
  }

  validate() {
    return validateSync(this);
  }
}
