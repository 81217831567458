import {ModelAbstraction} from "@/classes/abstractions/model.abstraction";
import {IsString} from "class-validator";

export class ApplicationModel extends ModelAbstraction {
  @IsString()
  applicationId!: string;

  @IsString()
  applicationName!: string;

  @IsString()
  configName!: string;

  @IsString()
  envName!: string;
}
